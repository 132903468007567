import { OxBackToTop } from "src/components/OxBackToTop";
import { OxComponentContainer } from "src/components/OxComponentContainer";
import React from "react";
import { WhyInjectables } from "src/components/Marketing/WhyInjectables";
import { BookAnAppointment } from "src/components/Marketing/BookAnAppointment";
import { Hero } from "src/components/Marketing/Hero";
import { AdvisoryPanel } from "src/components/Marketing/AdvisoryPanel";
import { OxPageHelmet } from "src/components/OxPageHelmet";

const Marketing3Page = (): JSX.Element => {
  return (
    <>
      <OxPageHelmet
        title="4 | Ouronyx | Luxury Facial Aesthetics"
        description="Ouronyx is the next generation of facial aesthetics. We’re the first global aesthetics destination, helping people to self-optimise with highly personalised, non-surgical treatments, delivered by the world’s best-in-class Doctors."
        keywords="Aesthetic doctor, non surgical, bespoke treatments, luxurious, St James, London, inspiring, leaders"
      />

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <Hero imageAlt={true} />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <AdvisoryPanel primarySliderKey="dr-iman" />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <WhyInjectables />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <BookAnAppointment />
      </OxComponentContainer>

      <OxBackToTop />
    </>
  );
};

export default Marketing3Page;
